import React, { useState, useEffect } from "react";
import styles from "./TopBarDesktopNotLoggedIn.module.scss";
import styles2 from "./InternalTopBarDesktop.module.scss";
import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
// import * as PropTypes from "prop-types";
import Container from "@material-ui/core/Container";
import Navbar from "react-bootstrap/Navbar";
import * as classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import TopBarMenu from "../TopBarMenu";
import UserInfomationTopBar from "../../UserTopBar/UserInfomationTopBar";
import UserTopBar, { InternalUserTopBar } from "../../UserTopBar/UserTopBar";
import { ReactComponent as HamburgerIconTransparent } from "../../../images/HamburgerIconTransparent.svg";
import { useRole } from "../../../HOCs/withRole";
import { Link } from "react-router-dom";


const HamburgerIcon = HamburgerIconTransparent;

const TopBarDesktop = ({ user, theme, isFullscreen }) => {

  const _NavBar = React.createRef()
  const [navbar, setNavbar] = useState(false)
  const setNavBarFixedTop = () => {
    window.addEventListener('scroll', () => {
      if (_NavBar.current) {
        if (window.scrollY >= (2 * 16)) {
          setNavbar(true)
          return
        }
        setNavbar(false)
      }
    })
  }
  
  useEffect(() => {
    setNavBarFixedTop()
  })

  const [_, urlHelper] = useRole();
  if (isFullscreen === true) return null;
  return (
    <>
      <div className={classnames(styles.supportLine, navbar && styles.withFixedTop)}>
        <Container maxWidth="xl" style={{ display: "flex" }}>
          <div className={styles.supportLineLeft}>
            <ul className={styles.menuSupportLine}>

              <li className={styles.supportLineItem}>
               
                <span style={{ fontSize: 13 }}>
                  Email: events@qooee.vn
                </span>
              </li>
            </ul>
          </div>
          <div className={styles.supportLineRight}>
            {user.status.includes("success") && user.obj ? (
              <UserInfomationTopBar />
            ) : (
              <div>
                <Link to={"/login"}>Đăng nhập /</Link>
                <Link to={"/register"}> Đăng ký</Link>
              </div>
            )}
          </div>
        </Container>
      </div>
      <Navbar
        className={classnames({
          [styles.NavBar]: true,
          [styles.NavBarDark]: theme === "dark",
        }, navbar && styles.fixedTop
        )}
        ref={_NavBar}
      >
        <Container maxWidth="xl" style={{display: "flex", alignItem: "center"}}>
          <div style={{ padding: `.25rem 0`, display: "flex", alignItems: "center" }}>
            <Link className={"no-link d-block"} to={"/events"}>
              {/* <Logo className={styles.Logo} style={{ height: "2rem" }} /> */}
              <img height={32} src="/QooeeLogo.png" alt="Qooee" className={styles.Logo} />
            </Link>
          </div>
          {/* <div className={styles.topBarMenuItem}>
          </div> */}
          <div className={user.obj ? styles.RightIcon : styles.RightIconUserNotLogin}>
            <TopBarMenu className={styles.TopBarMenu} theme={theme} />
            <UserTopBar />
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default TopBarDesktop;

export const InternalTopBarDesktop = ({ user, isFullscreen }) => {
  const _NavBar = React.createRef();
  const [_, urlHelper] = useRole();

  const setNavBarShadow = () => {
    window.addEventListener("scroll", () => {
      if (_NavBar.current) {
        // console.log(window.pageYOffset, _NavBar.current.offsetHeight)
        // if (window.pageYOffset <= _NavBar.current.offsetHeight) {
        //  1 * 16 == 1rem (paddingTop of appBody)
        if (window.pageYOffset <= 1 * 16) {
          _NavBar.current.classList.remove(styles2.NavBarShadow);
          return;
        }
        _NavBar.current.classList.add(styles2.NavBarShadow);
      }
    });
    // return window.removeEventListener('scroll', () => {})
  };

  useEffect(() => {
    setNavBarShadow();
  });

  if (isFullscreen === true) return null;
  return (
    <Navbar
      className={classnames({
        [styles2.NavBar]: true,
      })}
      fixed={"top"}
      style={{ zIndex: 99 }}
      ref={_NavBar}
    >
      <Navbar.Brand>
        <div className={styles2.LeftSection}>
          {/success/.test(user.status) && !isEmpty(user.obj) && (
            <HamburgerIcon className={styles2.HamburgerIcon} />
          )}
          <Link className={"no-link"} to={urlHelper("/")}>
            <img src="/QooeeLogo.png" alt="Qooee" className={styles2.Logo} />
          </Link>
        </div>
      </Navbar.Brand>
      <div className={styles2.RightIcon}>
        {user.status.includes("success") && user.obj && <InternalUserTopBar />}
      </div>
    </Navbar>
  );
};
