import React from "react";
import styles from "./Profile.module.scss";
import * as classnames from "classnames";
import PageTitle from "../../components/UI/PageTitle/PageTitle";
import PrivilegedComponent from "../../components/PrivilegedComponent/PrivilegedComponent";
import { withTopBarContext } from "../../utils/TopBarContext";
import UserTopBar from "../../components/UserTopBar/UserTopBar";
import { connect } from "react-redux";
import { ExhibitorActionFetchAll } from "../../store/exhibitor/actions";
import { withRouter } from "react-router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import withProps from "../../components/_helpers/withProps";
import FontAwesomeCircle from "../../components/UI/FontAwesomeCircle/FontAwesomeCircle";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import Form from "react-bootstrap/Form";
import withSimpleForm from "../../utils/SimpleForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import ReactSelect from "../../components/UI/ReactSelect/ReactSelect";
import { diff } from "deep-object-diff";
import cloneDeep from "lodash/cloneDeep";
import { datetime } from "../../utils/datetime";
import { parseUserStatus } from "../../utils/common";
import { axiosApi } from "../../utils/api";
import { adminFetchIUsers } from "../../store/admin/actions";
import { APIExternal, APIReplParams } from "../../utils/constant";
import { APIInternal } from "../../utils/constant";
import withToast from "../../HOCs/withToast";
import { Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import { getProfile } from "store/user/actions";
import ImageUpload from "components/UI/ImageUpload/ImageUpload";
import FileUpload from "components/UI/FileUpload/FileUpload";
import Nav from "components/Nav/Nav";
import DateTimePicker from "components/UI/DateTimePicker/DateTimePicker";

@withTopBarContext()
@connect(state => {
  console.log(state)
  return {
    user: state.user
  }
})
@withRouter
@withProps(props => ({
    currentUser: props.user?.obj
}))
@withSimpleForm({
  entries: props => (props.currentUser ? cloneDeep(props.currentUser) : {})
})
@withToast
class Profile extends React.Component {
  state = {
    loading: true,
    toggleField: "",
    addUser: false,
    currentUser: {},
    image: this.state?.currentUser?.profile_photo || '/user.jpeg'
  };
  // noinspection JSCheckFunctionSignatures
  componentDidMount() {
    const { dispatch, users = [] } = this.props;
    // topBarContext.setRightButton(() => <UserTopBar />);
    if (users.length === 0) {
    //   dispatch(ExhibitorActionFetchAll());
    dispatch(getProfile);
    }
    this.setState({ loading: false });
    console.log("this.currentUser", this.state.currentUser)
    this.setState({
      image: this.state?.currentUser?.profile_photo || '/user.jpeg'
    })
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.currentUser !== null &&
      JSON.stringify(diff(state.currentUser, props.currentUser)) !== "{}"
    )
      return { currentUser: props.currentUser };
    return {};
  }

  /**
   *
   * @private
   */
  _onSubmit() {
    const { form, dispatch, history } = this.props;
    axiosApi
      .auth()
      .put(
        APIReplParams(APIExternal.USER_PROFILE),
        {
          ...form,
          profile_photo: this.state.image
        }
      )
      .then(({ data }) => {
        if (data && data.success) {
          dispatch(getProfile);
          this.setState({ toggleField: "" });
          this.props.toastSuccess("Update user profile successfully");
        } else {
          this.props.toastError("There was a problem. Please try again.");
        }
      });
  }

onFileUploaded(e){
    console.log(e)
    if (e.length > 0) {
      this.setState({
        image: e[0]
      }, () => {
        this._onSubmit()
      })
    }
  }

  get diff() {
    if (
      JSON.stringify(this.props.form) === "{}" ||
      JSON.stringify(this.state.currentUser) === "{}"
    )
      return false;
    return (
      JSON.stringify(diff(this.props.form, this.state.currentUser)) !== "{}"
    );
  }

  /**
   *
   * @param label
   * @param value
   * @param editable
   * @param field
   * @param inputType
   * @param data
   * @returns {*}
   * @private
   */
  _renderEditField = (
    label,
    value,
    { editable = false, field = "", inputType = "text", data = [] } = {}
  ) => {
    const toggleField = this.state.toggleField;
    let valueDisplay = <div className={styles.EditFieldControl}>{value}</div>;
    if (editable && (toggleField === field || toggleField === "all")) {
      if (inputType === "select") {
        valueDisplay = (
          <div className={styles.EditFieldControl}>
            <ReactSelect
              options={data}
              value={data.find(x => x.value === this.props.form[field])}
              onChange={target => this.props.formHandler(field)({ target })}
            />
          </div>
        );
      } else {
        valueDisplay = (
          <div className={styles.EditFieldControl}>
            <Form.Control type={inputType} {...this.props.formBinding(field)} />
          </div>
        );
      }
    }

    return (
      <div>
        <div
          className={classnames("py-3", styles.EditField, {
            [styles.Toggle]: field === this.state.toggleField
          })}
        >
          {editable && (
            <FontAwesomeCircle
              className={styles.EditFieldButton}
              icon={faPencilAlt}
              onClick={() => {
                if (!editable) return;
                if (toggleField === "all") return;
                this.setState(state => ({
                  toggleField: state.toggleField === field ? "" : field
                }));
              }}
            />
          )}
          <div className={styles.EditFieldLabel}>
            <strong className="mr-3">{label}:</strong>
          </div>
          {valueDisplay}
        </div>
      </div>
    );
  };

  /**
   *
   * @returns {null|*}
   * @private
   */
  _renderEditPanel = () => {
    const { form: currentUser } = this.props;
    if (JSON.stringify(currentUser) === "{}") return null;
    return (
      <Row className={styles.EditPanelOuter}>
        <Col md={12}>
          <Form className={styles.EditPanel}>
            <Row>
              <Col>
                {this._renderEditField("Địa chỉ email", currentUser.email, {
                  editable: false,
                  field: "email"
                })}
              </Col>
              <Col>
                {this._renderEditField("Số điện thoại", currentUser.mobile, {
                  editable: true,
                  field: "mobile"
                })}
              </Col>
            </Row>
            <Row>
              <Col>
                {this._renderEditField("Tên", currentUser.first_name, {
                  editable: true,
                  field: "first_name"
                })}
              </Col>
              <Col>
                {this._renderEditField("Họ", currentUser.last_name, {
                  editable: true,
                  field: "last_name"
                })}
              </Col>
            </Row>

            {/* <Row>
              <Col>
                {this._renderEditField("Address", currentUser.address || "", {
                  editable: true,
                  field: "address"
                })}
              </Col>
            </Row> */}
            <Row>
              {/* <Col>
                {this._renderEditField("Birthday", new Date(currentUser.birthday || ""), {
                  editable: true,
                  as: DateTimePicker,
                  inputType: "date",
                  field: "birthday"
                })}
              </Col> */}
              <Col>
                {this._renderEditField(
                  "Tham gia vào",
                  datetime(currentUser.created_at),
                  {
                    editable: false
                  }
                )}
              </Col>
              {/* <Col>
                {this._renderEditField(
                  "Join Date",
                  datetime(currentUser.updated_at),
                  {
                    editable: false
                  }
                )}
              </Col> */}
            </Row>
          </Form>
        </Col>
      </Row>
    );
  };

  render() {
    const { currentUser } = this.props;
    if (this.props.loading) return null;
    return (
        <div className="container-xl px-4 mt-4">
            <Nav />
            <div className="row">
                <div className="col-md-4">
                    <div className="card mb-4 mb-xl-0">
                        <div className="card-header">Ảnh đại diện</div>
                        <div className="card-body text-center">
                        
                            <input className="d-none" type="file" onChange={e => this.onFileUploaded(e)} />
                            <img height={170} width={170} className="img-account-profile rounded-circle mb-2" src={this.state.image} alt="" />
                            <div className="small font-italic text-muted mb-4">JPG hoặc PNG có dung lượng không quá 5 MB</div>
                            <FileUpload
                              // accept={".jpeg|.png|.jpg|.gif"}
                              value={[currentUser?.profile_photo]}
                              onChange={e => this.onFileUploaded(e.target.value)}
                              tooltipPlacement={"bottom"}
                            >
                              Đổi ảnh đại diện
                            </FileUpload>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="card mb-4">
                        <div className="card-header">
                            Thông tin tài khoản
                            <small
                                className="text-primary ml-3"
                                style={{
                                    fontSize: "70%",
                                    fontWeight: "500",
                                    cursor: "pointer",
                                    textDecoration: "underline"
                                }}
                                onClick={() =>
                                    this.setState(({ toggleField: old }) => ({
                                    toggleField: old === "all" ? "" : "all"
                                    }))
                                }
                            >
                                Sửa đổi
                            </small>
                        </div>
                        <div className="card-body">
                        {this._renderEditPanel()}
                        <Button disabled={!this.diff} onClick={this._onSubmit.bind(this)}>
                            Lưu thay đổi
                        </Button>
                            {/* <form>
                                <div className="row gx-3 mb-3">
                                    <div className="col-md-6">
                                        <label className="small mb-1" for="inputFirstName">First name</label>
                                        <input className="form-control" id="inputFirstName" type="text" placeholder="Enter your first name" value="Valerie" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="small mb-1" for="inputLastName">Last name</label>
                                        <input className="form-control" id="inputLastName" type="text" placeholder="Enter your last name" value="Luna" />
                                    </div>
                                </div>
                                <div className="row gx-3 mb-3">
                                    <div className="col-md-12">
                                        <label className="small mb-1" for="inputLocation">Address</label>
                                        <input className="form-control" id="inputLocation" type="text" placeholder="Enter your address" value="San Francisco, CA" />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="small mb-1" for="inputEmailAddress">Email address</label>
                                    <input className="form-control" id="inputEmailAddress" type="email" placeholder="Enter your email address" value="name@example.com" />
                                </div>
                                <div className="row gx-3 mb-3">
                                    <div className="col-md-6">
                                        <label className="small mb-1" for="inputPhone">Phone number</label>
                                        <input className="form-control" id="inputPhone" type="tel" placeholder="Enter your phone number" value="555-123-4567" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="small mb-1" for="inputBirthday">Birthday</label>
                                        <input className="form-control" id="inputBirthday" type="text" name="birthday" placeholder="Enter your birthday" value="06/10/1988" />
                                    </div>
                                </div>
                                <button className="btn btn-primary" type="button">Save changes</button>
                            </form> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    );
  }
}

export default Profile;
