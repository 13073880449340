import React from "react";
import styles from "./ImageUpload.module.scss";
import * as PropTypes from "prop-types";
import withFileUpload from "../../../HOCs/withFileUpload";
// import range from "lodash/range";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { diff } from "deep-object-diff";
import fastdom from "fastdom";
import fastdomPromise from "fastdom/extensions/fastdom-promised";
import classnames from "classnames";
import withProps from "../../_helpers/withProps";
import { ReactComponent as SpinningIcon } from "../../../images/anim/Spinning.svg";
import preloadImage from "../../../utils/preloadImage";
import { branch } from "../../../utils/common";

// import { timeout } from "../../../utils/common";

const myFastdom = fastdom.extend(fastdomPromise),
  pxToNum = px => px.replace(/px/, "") * 1.0;

const WithSingle = Component => ({ value, onChange }) => {
  return (
    <Component
      value={value?.length === 0 ? [] : [value]}
      onChange={e =>
        onChange({
          target: {
            value: e.target.value[0]
          }
        })
      }
      max={1}
    />
  );
};

@branch(props => props.single === true, WithSingle)
@withFileUpload({
  accept: "image/*",
  multiple: true
})
@withProps(props => ({
  uploadable:
    typeof props.readOnly === "boolean"
      ? props.readOnly
      : props.value.length < props.max
}))
class ImageUpload extends React.PureComponent {
  /**
   * @type {HTMLElement}
   * @private
   */
  $inner = null;

  state = { width: 9999 };

  getSnapshotBeforeUpdate(prevProps, prevState) {
    return { valueChange: prevProps.value.length !== this.props.value.length };
  }
  componentDidMount() {
    // console.log("update");
    this._resizeInnerEl();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot.valueChange) this._resizeInnerEl();
  }

  /**
   *
   * @returns {Promise<void>}
   * @private
   */
  _resizeInnerEl = () => {
    let self = this;
    let width = 0;
    myFastdom
      .measure(function() {
        if (self.$inner === null) return;
        for (let el of self.$inner.children) {
          const elStyle = window.getComputedStyle(el),
            [elW, elMl, elMr] = [
              "width",
              "margin-left",
              "margin-right"
            ].map(prop => pxToNum(elStyle.getPropertyValue(prop)));
          width += elW + elMl + elMr;
        }
      })
      .then(() => {
        width > 0 && self.setState({ width });
      });
  };

  render() {
    const self = this,
      { FileUploadHandler } = self.props.fileUpload;
    return (
      <FileUploadHandler onUploadCompleted={preloadImage}>
        <div className={styles.ImageUpload}>
          <div
            className={styles.ImageUploadInner}
            ref={el => (self.$inner = el)}
            style={{ width: self.state.width + "px" }}
          >
            {self.props.value.map(({ value, state }, index) => (
              <span
                className={styles.Image}
                key={`img__${value?.hashCode() || index}_${index}`}
              >
                {state === "uploading" ? (
                  <SpinningIcon />
                ) : (
                  <a href={value} target="_blank" rel="noopener noreferrer"><img src={value} alt={value} /></a>
                )}
                <span
                  className={styles.Remove}
                  onClick={() => self.props.fileUpload.deleteFile(index)}
                >
                  ×
                </span>
              </span>
            ))}
            <span
              className={classnames({
                [styles.AddImage]: true,
                [styles.Disabled]: self.props.uploadable === false
              })}
              key={"img__add"}
              onClick={() =>
                self.props.uploadable && self.props.fileUpload.openFileDialog()
              }
            >
              <div>
                <FontAwesomeIcon icon={faPlus} />
              </div>
            </span>
          </div>
        </div>
      </FileUploadHandler>
    );
  }
}

ImageUpload.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  max: PropTypes.number
};
ImageUpload.defaultProps = {
  max: 3
};

export default ImageUpload;
