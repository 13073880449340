import React, { useState, useEffect }  from "react";
// import styles from "./SideBarDesktop.module.scss";
import styles2 from "./InternalSideBarDesktop.module.scss";
// import * as PropTypes from "prop-types";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Collapse from 'react-bootstrap/Collapse';
import * as classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import { ReactComponent as HamburgerIconTransparent } from "../../../images/HamburgerIconTransparent.svg";
import { useRole } from "../../../HOCs/withRole";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { 
  Person, PersonAdd, PeopleAlt,
  RecentActors, SupervisedUserCircleOutlined, SupervisedUserCircle,
  SupervisorAccount, SupervisorAccountOutlined, People, PeopleOutline,
  Event, NoteAdd, Receipt,
  Group, GroupAdd, DonutLarge, BarChart,
  Share, InfoOutlined, Help
} from '@material-ui/icons';

const HamburgerIcon = HamburgerIconTransparent

export const InternalSideBarDesktop = ({ user, theme }) => {
  const _SideBar = React.createRef(),
        history = useHistory()
        
  const defaultStateMenu = {
    general: false,
    infomation: false,
  }
  const [stateMenu, setStateMenu] = useState(defaultStateMenu)

  const handleDropdownToggle = (e) => {
    let menu = e.currentTarget.dataset?.menu
    setStateMenu({ ...defaultStateMenu, [menu]: !stateMenu[menu] })
  }

  useEffect(() => {
    // Open one specific Menu on component mount
    setStateMenu({
      ...defaultStateMenu,
      [Object.keys(defaultStateMenu)[1]]: true
    })
  }, [])

  return (
    <Navbar
      className={styles2.SideBar}
      fixed={"top"}
      style={{ zIndex: 99 }}
      ref={_SideBar}
    >
      <Nav className="flex-column">

        <Nav.Item>
          <Nav className={`${styles2.Items} flex-column`}>
            <Nav.Item onClick={() => history.push("/internal")}>
              <span><DonutLarge /></span>
              Dashboard
            </Nav.Item>
            <Nav.Item className={`${styles2.DropdownItem}`}>
              <div
                data-menu={`general`}
                onClick={handleDropdownToggle}
                className={stateMenu.general && styles2.DropdownItemShow}
              >
                <span><BarChart /></span>
                Analytics
              </div>
              <Collapse in={stateMenu.general} className={`${styles2.DropdownMenu}`}>
                <Nav className={`flex-column`}>
                  <Nav.Item>Day</Nav.Item>
                  <Nav.Item>Week</Nav.Item>
                  <Nav.Item>Month</Nav.Item>
                  <Nav.Item>Year</Nav.Item>
                </Nav>
              </Collapse>
            </Nav.Item>
            <Nav.Item className={`${styles2.DropdownItem}`}>
              <div
                data-menu={`infomation`}
                onClick={handleDropdownToggle}
                className={stateMenu.infomation && styles2.DropdownItemShow}
              >
                <span><InfoOutlined /></span>
                Information
              </div>
              <Collapse in={stateMenu.infomation} className={`${styles2.DropdownMenu}`}>
                <Nav className={`flex-column`}>
                  <Nav.Item>About us</Nav.Item>
                  <Nav.Item>Terms Of Use</Nav.Item>
                  <Nav.Item>Chính sách bảo mật</Nav.Item>
                </Nav>
              </Collapse>
            </Nav.Item>
          </Nav>
        </Nav.Item>

        <Nav.Item>
          <Navbar.Brand className={styles2.VerticalLabel}>
            <span className={styles2.Label}>Users</span>
          </Navbar.Brand>
          <Nav className={`${styles2.Items} flex-column`}>
            <Nav.Item
              className={`${styles2.ItemActive}`}
              onClick={() => history.push("/internal/users")}
            >
              <span><RecentActors /></span>
              Management
            </Nav.Item>
            {/* <Nav.Item>
              <span><People /></span>
              Internal users
            </Nav.Item>
            <Nav.Item>
              <span><PeopleOutline /></span>
              External users
            </Nav.Item> */}
            <Nav.Item onClick={() => history.push("/internal/register")}>
              <span><PersonAdd /></span>
              Create new user
            </Nav.Item>
          </Nav>
        </Nav.Item>

        <Nav.Item>
          <Navbar.Brand className={styles2.VerticalLabel}>
            <span className={styles2.Label}>Exhibitors</span>
          </Navbar.Brand>
          <Nav className={`${styles2.Items} flex-column`}>
            <Nav.Item onClick={() => history.push("/internal/exhibitors")}>
              <span><PeopleAlt /></span>
              Management
            </Nav.Item>
            {/* <Nav.Item>
              <span><GroupAdd /></span>
              Create new exhibitor
            </Nav.Item> */}
          </Nav>
        </Nav.Item>

        <Nav.Item>
          <Navbar.Brand className={styles2.VerticalLabel}>
            <span className={styles2.Label}>Events</span>
          </Navbar.Brand>
          <Nav className={`${styles2.Items} flex-column`}>
            <Nav.Item onClick={() => history.push("/internal/event")}>
              <span><Event /></span>
              Management
            </Nav.Item>
            <Nav.Item onClick={() => history.push("/internal/event?modal=addEvent&modalSize=xl")}>
              <span><NoteAdd /></span>
              Add new event
            </Nav.Item>
          </Nav>
        </Nav.Item>

        <Nav.Item>
          <Navbar.Brand className={styles2.VerticalLabel}>
            <span className={styles2.Label}>Booking</span>
          </Navbar.Brand>
          <Nav className={`${styles2.Items} flex-column`}>
            <Nav.Item onClick={() => history.push("/internal/payment/manage")}>
              <span><Receipt /></span>
              Manage bookings
            </Nav.Item>
          </Nav>
        </Nav.Item>

      </Nav>
    </Navbar>
  )
}