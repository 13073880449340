import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styles from "./UserTopBar.module.scss";
import NotificationsIcon from '@material-ui/icons/Notifications';
import Dropdown from 'react-bootstrap/Dropdown';
import { default as Dropdown2 } from 'react-bootstrap/Dropdown';
import { compose } from "redux";
import classNames from "classnames";
import Moment from "react-moment";
import withNotification from "HOCs/withNotification";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { Button } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { requestForToken } from "firebase";

const NotificationMenu = compose(
    withNotification
)(
  ({ notifications, fetchNotification, readNotification, readAllNotification, notiEnabled, ...props }) => {
    const dropDown = useRef();
    const history = useHistory(); 
    const NotificationButton = React.forwardRef(({ onClick }, ref) => {
        const length = notifications?.unread || 0;//cart?.booths?.length ? cart?.booths?.length : 0
        return (
        <div
            style={{ marginLeft: `1rem`, cursor: "pointer" }}
            ref={ref}
            onClick={(e) => {
                e.preventDefault()
                props.mobile === false ? onClick(e) : history.push("/notifications")
            }}>
            <NotificationsIcon style={{ color: "#333" }} />
            {length > 0 && <span style={{
            backgroundColor: "#ff681e",
            color: "white",
            width: 16,
            height: 16,
            position: "absolute",
            fontSize: 11,
            borderRadius: 10,
            textAlign: "center",
            right: -3,
            top: -6,
            }}>{length < 20 ? length : "20+"}</span>}
        </div>

        )
    })

    const toggle = (id) => {
        dropDown.current.click();
        // console.log(dropDown)
        readNotification(id);
    }

    const notNow = () => {

    } 

    const getPermission = () => {
        
        // Your current browser is not supported. Please enable the permission to display notifications in your browser or contact us for instructions
        requestForToken()
        .then((token) => {
            if (token === "err")
                dropDown.current.click();
                confirmAlert({
                    message: "Your current browser is not supported. Please enable the permission to display notifications in your browser or contact us for instructions.",
                    buttons: [
                        { 
                            label: "OK",
                        }
                    ]
                })
        })
        .catch((err) => {
        });
    }

    return (
        <Dropdown className={props.className}>
            <Dropdown.Toggle as={NotificationButton} ref={dropDown} />
            <Dropdown.Menu align={'left'} className={classNames(styles.DropdownNoti, styles.DropdownCart)}>
            <Dropdown2.Header className={`${styles.DropdownCartEventName} d-flex justify-content-between`}>
                <div>
                    <strong>Thông báo</strong>
                </div>
                    <div>
                        <ins
                            onClick={readAllNotification}
                            className={styles.DropdownClearCart}
                        >Đánh dấu đã đọc</ins>
                    </div>
                </Dropdown2.Header>
            <Divider component="li" />
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} className="pt-0">
                {!notiEnabled && <>
                    <ListItem alignItems="flex-start" className="pl-4" style={{ backgroundColor: "antiquewhite" }}>
                            {/* <button style={{ 
                                position: "absolute",
                                top: 20,
                                right: 20,
                                border: "none",
                                width: 30,
                                height: 30,
                                borderRadius: 15
                            }}
                            onClick={notNow}>✕</button> */}
                            <ListItemText
                                primary={"Turn On Notifications"}
                                secondary={
                                <React.Fragment>
                                    <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                    >
                                        <span className="pr-2">Bật thông báo trên thiết bị của bạn để nhận thông tin mới nhất từ chúng tôi,<br />ngay cả khi bạn không truy cập trang web.</span>
                                    </Typography>
                                </React.Fragment>
                                }
                            />
                            <div className="d-flex" style={{ alignSelf: "center" }}>
                                <Button onClick={getPermission} variant="secondary" style={{ fontSize: 13, padding: "3px 4px", width: 72 }}>Turn on</Button>
                            </div>
                    </ListItem>
                    <Divider component="li" />
                </>}
                {notifications?.total === 0 && 
                <>
                    <ListItem alignItems="flex-center">
                        <span className="text-center p-2 w-100" style={{ fontSize: 13 }}>Không có thông báo nào</span>
                    </ListItem>
                    <Divider component="li" />
                </>
                }
                {
                    notifications?.notifications?.slice(1, 4).map(notification => (<>
                        <ListItem alignItems="flex-start" className="pl-4" style={notification.unread === 1 ? { backgroundColor: "antiquewhite" } : {}}>
                        <Link onClick={() => toggle(notification.id)} to={notification.url} className={styles.LinkNoUnderlined}>
                                <ListItemText
                                    primary={notification.title}
                                    secondary={
                                    <React.Fragment>
                                        <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                        >
                                            <Moment from={new Date()}>{notification.created_at}</Moment>
                                        </Typography>
                                        {` — ${notification.body}`}
                                    </React.Fragment>
                                    }
                                />
                                </Link>
                                {notification.image && <ListItemAvatar>
                                    <Avatar className="mr-0 ml-2" alt={notification.title} variant="rounded" src={notification.image} />
                                </ListItemAvatar>}
                        </ListItem>
                        <Divider component="li" />
                    </>))
                }

                
                <h6 className="text-center px-4 pt-3" style={{ fontSize: "0.8rem", cursor: "pointer" }}><Link onClick={() => dropDown.current.click()} to="/notifications" >Xem tất cả thông báo</Link></h6>
                
            </List>
            </Dropdown.Menu>
        </Dropdown>
        
    )
  }
);

export default NotificationMenu;