import React from "react";
import styles from "./ExhibitorAddConsent.module.scss";
// import * as PropTypes from "prop-types";

const ExhibitorAddConsent = ({ children }) => (
  <div className="mt-5 d-flex flex-column align-items-center">
    <div className={styles.NoExhibitor}>
      <h5>Chào mừng bạn đến với Qooee!</h5>
      <p>
      Để đặt gian hàng ở sự kiện, bạn phải có tài khoản người bán hàng. Để tạo tài khoản bán hàng, vui lòng nhấn vào nút bên dưới.
      </p>
    </div>
    {children}
  </div>
);

export default ExhibitorAddConsent;
