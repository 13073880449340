import React from "react";
import { connect } from "react-redux";
import { GetUserRoles } from "../store/user/selectors";
import {
  BookingComputedGetCartTotal,
  BookingSelectorGetCart
} from "../store/booking/selector";
import {
  BookingActionAddToCart,
  BookingActionClearCart,
  BookingActionRemoveFromCart
} from "../store/booking/actions";
import { ExhSelectorGetCurrent } from "../store/exhibitor/selectors";
import withToast from "./withToast";

const withCart = (resolveEIDfn, resolveEIDdep) => Component => {
  @withToast
  @connect(state => ({
    BookingSelectorGetCart: BookingSelectorGetCart(state),
    currentExhibitor: ExhSelectorGetCurrent(state)
  }))
  class WithCart extends React.Component {
    state = {
      eventId: null
    };
    componentDidMount() {
      // debugger;
      this._processResolve();
    }
    getSnapshotBeforeUpdate(prevProps, prevState) {
      const snapshot = {};
      if (prevProps[resolveEIDdep] !== this.props[resolveEIDdep])
        snapshot.dep = true;
      return snapshot;
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
      if (snapshot.dep) this._processResolve();
    }

    /**
     *
     * @returns {*}
     * @private
     */
    get _cart() {
      return this.props.BookingSelectorGetCart(this.state.eventId);
    }

    /**
     *
     * @private
     */
    _processResolve = () =>
      this.setState({ eventId: resolveEIDfn(this.props) });

    /**
     *
     * @param boothId
     * @returns {*}
     * @private
     */
    _addToCart = (boothId, boothNo = false) => {
      var isError = false
      var errorCode = 0;
      return this.props
        .dispatch(BookingActionAddToCart(this.state.eventId, boothId))
        .catch(err => {
          if (/reservation/.test(err.message) || err.code === -120) {
            isError = true
          }
          errorCode = err && err.code;
        })
        .then(() => {
          if (isError) {
            this.props.toastError(errorCode === -120 ? "Sorry, someone else has reserved this booth! Please refresh your screen!" : "Sorry, the reservation time is up!")
          }
          else {
            this.props.toastSuccess(<>Added <strong>{boothNo ? boothNo : `(Unknown)`}</strong> to cart!</>)
          }
        })
    };

    /**
     *
     * @param cartItemId
     * @returns {*}
     * @private
     */
    _removeFromCart = cartItemId => {
      return this.props.dispatch(
        BookingActionRemoveFromCart(this.state.eventId, cartItemId)
      );
    };

    /**
     *
     * @returns {*}
     * @private
     */
    _removeAllFromCart = () => {
      const cartItemIds = this._cart.booths.map(booth => booth.id);
      return this.props.dispatch(
        BookingActionClearCart(this.state.eventId, cartItemIds)
      );
    };

    render() {
      const self = this;
      if (typeof self.state.eventId !== "number") return null;
      const { BookingSelectorGetCart, ...props } = self.props;
      return (
        <Component
          {...props}
          cart={self._cart}
          cartParams={{
            exhibitorId: self.props.currentExhibitor?.id,
            eventId: self.state.eventId
          }}
          cartTotal={{
            price: BookingComputedGetCartTotal(self._cart),
            count: self._cart?.booths?.length || 0
          }}
          cartOperations={{
            addToCart: self._addToCart,
            removeFromCart: self._removeFromCart,
            removeAllFromCart: self._removeAllFromCart
          }}
        />
      );
    }
  }

  return connect(state => ({ role: GetUserRoles(state) }))(
    ({ role, dispatch, ...props }) => {
      if (false /*role.isNotClient removed*/) return <Component {...props} />;
      return <WithCart {...props} />;
    }
  );
};

export default withCart;
