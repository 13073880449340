import React from "react";
import "../../App.scss";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './Footer.module.scss'

// @withTranslation()
const Footer = props => {
  return (
    <div className={styles.FooterContainer}>
      <Container>
        <footer className="pt-5">
          <div className="row">
            <div className="col-12 col-md-5 mb-3">
              {/* <ul className="nav flex-column">
                <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Home</a></li>
                <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Features</a></li>
                <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Pricing</a></li>
                <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">FAQs</a></li>
                <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">About</a></li>
              </ul> */}
              <a href="/" className="d-flex align-items-center mb-4 link-dark text-decoration-none">
                <img src="/QooeeLogo.png" className="bi me-2" height="60" />
              </a>
              <h5 className="mb-3">CÔNG TY TNHH QOOEE VIỆT NAM</h5>
              <p className="text-muted">Đăng ký kinh doanh số 0316795017 ngày 2021-04-07</p>
              <p className="text-muted">Hotline: +84-372-125526</p>
            </div>
      
            {/* <div className="col-6 col-md-2 mb-3">
              <h5>Section</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Home</a></li>
                <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Features</a></li>
                <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Pricing</a></li>
                <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">FAQs</a></li>
                <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">About</a></li>
              </ul>
            </div> */}
      
            <div className="col-6 col-md-2 mb-3">
              <h6 className="mb-3">Truy cập nhanh</h6>
              <ul className="nav flex-column">
                <li className="nav-item mb-2"><a href="/about-us" className="nav-link p-0 text-muted">Về chúng tôi</a></li>
                <li className="nav-item mb-2"><a href="/terms-of-contract" className="nav-link p-0 text-muted">Điều khoản sử dụng</a></li>
                <li className="nav-item mb-2"><a href="/privacy-policy" className="nav-link p-0 text-muted">Chính sách bảo mật</a></li>
              </ul>
            </div>
      
            <div className="col-md-4 offset-md-1 mb-3">
              <form>
                <h6>Phương thức thanh toán</h6>
                <p>Chúng tôi chấp nhận thanh toán qua các đối tác thanh toán sau</p>
                <a href="/" className="d-flex align-items-center mb-4 link-dark text-decoration-none">
                  <img src="/logo-stripe.png" className="bi me-2 mr-2" height="40" />
                  {/* <img src="/logo-banktransfer.png" className="bi me-2 mr-2" height="40" /> */}
                  <img src="/logo-vietqr.png" className="bi me-2 mr-2" height="30" />
                  <img src="/logo-momo.png" className="bi me-2 mr-2" height="40" />
                </a>
                <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                  <button className="btn btn-primary" type="button"><Link className="text-white" href="/register" to="/register">Đăng ký gian hàng</Link></button>
                </div>
              </form>
            </div>
          </div>
      
          <div className="d-flex flex-column flex-sm-row justify-content-between py-4 mt-4 border-top">
            <p>Copyright ©{new Date().getFullYear()} Qooee Co. Ltd. All rights reserved.</p>
            <ul className="list-unstyled d-flex">
              <li className="ms-3">
                <a href="//www.dmca.com/Protection/Status.aspx?ID=53aa5812-8eea-47c6-a8a2-2b871882481b" title="DMCA.com Protection Status" class="dmca-badge"> <img src ="https://images.dmca.com/Badges/dmca_protected_sml_120l.png?ID=53aa5812-8eea-47c6-a8a2-2b871882481b"  alt="DMCA.com Protection Status" /></a>
              </li>
            </ul>
          </div>
        </footer>
      </Container>
    </div>

  );
};

export default Footer;

export const InternalFooter = () => {
  return (null
    // <div classNameName={`AppFooter`}>
    //   {/* <Row>
    //     <Col xl={12} xs={12}>
    //       <small classNameName="text-grey">
    //         Copyright ©{new Date().getFullYear()} Qooee Co. Ltd. . All rights reserved.
    //       </small>
    //     </Col>
    //   </Row> */}
    // </div>
  )
}