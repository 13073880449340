import React from "react";
import styles from "./ChangePassword.module.scss";
import * as classnames from "classnames";
import { withTopBarContext } from "../../utils/TopBarContext";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import withProps from "../../components/_helpers/withProps";
import FontAwesomeCircle from "../../components/UI/FontAwesomeCircle/FontAwesomeCircle";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import Form from "react-bootstrap/Form";
import withSimpleForm from "../../utils/SimpleForm";
import Button from "react-bootstrap/Button";
import ReactSelect from "../../components/UI/ReactSelect/ReactSelect";
import { diff } from "deep-object-diff";
import cloneDeep from "lodash/cloneDeep";
import { datetime } from "../../utils/datetime";
import { axiosApi } from "../../utils/api";
import { APIExternal, APIReplParams } from "../../utils/constant";
import withToast from "../../HOCs/withToast";
import { Link } from "react-router-dom";
import { fetchUserSuccess, getProfile } from "store/user/actions";
import FileUpload from "components/UI/FileUpload/FileUpload";
import PasswordInput from "components/UI/PasswordInput/PasswordInput";
import { appValidators, createValidator, withToken } from "../../utils/form";
import Nav from "components/Nav/Nav";
@withTopBarContext()
@connect(state => {
  console.log(state)
  return {
    user: state.user
  }
})
@withRouter
@withProps(props => ({
    currentUser: props.user?.obj,
    authentication: props.user?.authentication
}))
@withSimpleForm({
  entries: props => ({
    password: "",
    new_password: "",
    confirm_password: ""
  })
})
@withToast
class ChangePassword extends React.Component {
  state = {
    loading: true,
    toggleField: "all",
    addUser: false,
    currentUser: {},
  };

  _validator;
  constructor(props) {
    super(props);

    this._validator = createValidator(this, {
      validators: {
        password: appValidators.password
      }
    });
  }

  // noinspection JSCheckFunctionSignatures
  componentDidMount() {
    const { dispatch, users = [] } = this.props;
    // topBarContext.setRightButton(() => <UserTopBar />);
    if (users.length === 0) {
    //   dispatch(ExhibitorActionFetchAll());
    dispatch(getProfile);
    }
    this.setState({ loading: false });
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.currentUser !== null &&
      JSON.stringify(diff(state.currentUser, props.currentUser)) !== "{}"
    )
      return { currentUser: props.currentUser };
    return {};
  }

  /**
   *
   * @private
   */
  _onSubmit() {
    const { form, dispatch, history } = this.props;
    axiosApi
      .auth()
      .put(
        APIReplParams(APIExternal.CHANGE_PASS),
        {
          ...form
        }
      )
      .then(({ data }) => {
        if (data && data.success) {
          dispatch(fetchUserSuccess({
            ...this.props.currentUser,
            authentication: {
              ...this.props.authentication,
              password: form.new_password
            }
          }));
          this.props.toastSuccess("Update password successfully");
        } else {
          this.props.toastError("There was a problem. Please try again.");
        }
      });
  }

  get diff() {
    if (
      JSON.stringify(this.props.form) === "{}" ||
      JSON.stringify(this.state.currentUser) === "{}"
    )
      return false;
    return (
      JSON.stringify(diff(this.props.form, this.state.currentUser)) !== "{}"
    );
  }

  /**
   *
   * @param label
   * @param value
   * @param editable
   * @param field
   * @param inputType
   * @param data
   * @returns {*}
   * @private
   */
  _renderEditField = (
    label,
    value,
    { editable = false, field = "", inputType = "text", data = [] } = {}
  ) => {
    const toggleField = this.state.toggleField;
    let valueDisplay = <div className={styles.EditFieldControl}>{value}</div>;
    if (editable && (toggleField === field || toggleField === "all")) {
      if (inputType === "select") {
        valueDisplay = (
          <div className={styles.EditFieldControl}>
            <ReactSelect
              options={data}
              value={data.find(x => x.value === this.props.form[field])}
              onChange={target => this.props.formHandler(field)({ target })}
            />
          </div>
        );
      } else {
        valueDisplay = (
          <div className={styles.EditFieldControl}>
            <Form.Control type={inputType} {...this.props.formBinding(field)} />
          </div>
        );
      }
    }

    return (
      <div>
        <div
          className={classnames("py-3", styles.EditField, {
            [styles.Toggle]: field === this.state.toggleField
          })}
        >
          <div className={styles.EditFieldLabel}>
            <strong className="mr-3">{label}:</strong>
          </div>
          {valueDisplay}
        </div>
      </div>
    );
  };

  /**
   *
   * @returns {null|*}
   * @private
   */
  _renderEditPanel = () => {
    const { form: currentUser } = this.props;
    if (JSON.stringify(currentUser) === "{}") return null;
    return (
      <Row className={styles.EditPanelOuter}>
        <Col>
        <Form>
              <Form.Group>
              <Form.Label style={{ fontWeight: 600, color: 'black' }}>Mật khẩu hiện tại</Form.Label>
                <PasswordInput
                  placeholder={""}
                  size={"lg"}
                  label={" "}
                  {...this.props.formBinding("password")}
                >
                  {this._validator.message(
                    "Password",
                    this.props.form.password,
                    "required|password"
                  )}
                </PasswordInput>
              </Form.Group>
              <Form.Group>
                <Form.Label style={{ fontWeight: 600, color: 'black' }}>Mật khẩu mới</Form.Label>
                <PasswordInput
                  placeholder={""}
                  size={"lg"}
                  label={" "}
                  {...this.props.formBinding("new_password")}
                >
                  {this._validator.message(
                    "Password",
                    this.props.form.password,
                    "required|password"
                  )}
                </PasswordInput>
              </Form.Group>
              <Form.Group>
                <Form.Label style={{ fontWeight: 600, color: 'black' }}>Xác nhận lại mật khẩu</Form.Label>
                <PasswordInput
                  size={"lg"}
                  label={" "}
                  placeholder={"Confirm Password"}
                  {...this.props.formBinding("confirm_password")}
                >
                  {[
                    this._validator.message(
                      "Confirm Password",
                      this.props.form.confirm_password,
                      "required|password"
                    ),
                    this._validator.message(
                      "",
                      this.props.form.confirm_password ===
                      this.props.form.new_password,
                      "accepted",
                      {
                        messages: {
                          default: "Confirm password doesn't match password."
                        }
                      }
                    )
                  ]}
                </PasswordInput>
              </Form.Group>
              <Form.Group>
                <div className="d-flex">
                  <Button
                    variant={"primary"}
                    size={"lg"}
                    className={"align-self-end"}
                    style={{ paddingLeft: "2rem", paddingRight: "2rem", marginTop: 20 }}
                    onClick={() => {
                      if (!this._validator.allValid()) {
                        this._validator.showMessages();
                        return;
                      }
                      // noinspection JSIgnoredPromiseFromCall
                      this._onSubmit();
                    }}
                  >
                    Đổi mật khẩu
                  </Button>
                </div>
              </Form.Group>
            </Form>
            </Col>
        {/* <Col md={12}>
          <Form className={styles.EditPanel}>
            <Row>
              <Col>
                {this._renderEditField("Current Password", "", {
                  editable: true,
                  inputType: "password",
                  field: "password"
                })}
              </Col>
            </Row>
            <Row>
              <Col>
                {this._renderEditField("New Password", "", {
                  editable: true,
                  inputType: "password",
                  field: "new_password"
                })}
              </Col>
            </Row>
            <Row>
              <Col>
                {this._renderEditField("Confirm Password", "", {
                  editable: true,
                  inputType: "password",
                  field: "confirm_password"
                })}
              </Col>
            </Row>

          </Form>
        </Col> */}
      </Row>
    );
  };

  render() {
    const { currentUser } = this.props;
    if (this.props.loading) return null;
    return (
        <div className="container-xl px-4 mt-4">
            <Nav />
            <div className="row">
                <div className="col-xl-12">
                    <div className="card mb-4">
                        <div className="card-header">
                            Thay đổi mật khẩu
                            {/* <small
                                className="text-primary ml-3"
                                style={{
                                    fontSize: "70%",
                                    fontWeight: "500",
                                    cursor: "pointer",
                                    textDecoration: "underline"
                                }}
                                onClick={() =>
                                    this.setState(({ toggleField: old }) => ({
                                    toggleField: old === "all" ? "" : "all"
                                    }))
                                }
                            >
                                Edit
                            </small> */}
                        </div>
                        <div className="card-body">
                        {this._renderEditPanel()}
                            {/* <form>
                                <div className="row gx-3 mb-3">
                                    <div className="col-md-6">
                                        <label className="small mb-1" for="inputFirstName">First name</label>
                                        <input className="form-control" id="inputFirstName" type="text" placeholder="Enter your first name" value="Valerie" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="small mb-1" for="inputLastName">Last name</label>
                                        <input className="form-control" id="inputLastName" type="text" placeholder="Enter your last name" value="Luna" />
                                    </div>
                                </div>
                                <div className="row gx-3 mb-3">
                                    <div className="col-md-12">
                                        <label className="small mb-1" for="inputLocation">Address</label>
                                        <input className="form-control" id="inputLocation" type="text" placeholder="Enter your address" value="San Francisco, CA" />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="small mb-1" for="inputEmailAddress">Email address</label>
                                    <input className="form-control" id="inputEmailAddress" type="email" placeholder="Enter your email address" value="name@example.com" />
                                </div>
                                <div className="row gx-3 mb-3">
                                    <div className="col-md-6">
                                        <label className="small mb-1" for="inputPhone">Phone number</label>
                                        <input className="form-control" id="inputPhone" type="tel" placeholder="Enter your phone number" value="555-123-4567" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="small mb-1" for="inputBirthday">Birthday</label>
                                        <input className="form-control" id="inputBirthday" type="text" name="birthday" placeholder="Enter your birthday" value="06/10/1988" />
                                    </div>
                                </div>
                                <button className="btn btn-primary" type="button">Save changes</button>
                            </form> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    );
  }
}

export default ChangePassword;
