import { ExhSelectorGetCurrent } from "../exhibitor/selectors";
import { createSelector } from "reselect";
import memoize from "lodash/memoize";

export const BookingSelectorGetCartOfExhibitor = createSelector(
  [ExhSelectorGetCurrent, state => state.booking],
  (currentExhibitor, { cart, cartSelector }) => {
    const cartIds = Object.values(cartSelector[currentExhibitor?.id] || []);
    return cartIds.map(id => cart[id] || null);
  }
);
export const BookingSelectorGetCart = createSelector(
  [ExhSelectorGetCurrent, state => state.booking],
  (currentExhibitor, selectorObj) => eventId => {
    if (!selectorObj) return null;
    const { cart, cartSelector } = selectorObj;
    const cartId = cartSelector[currentExhibitor.id]?.[eventId];
    return cart[cartId] || null;
  }
);
export const BookingSelectorGetCartStatus = createSelector(
  [ExhSelectorGetCurrent, state => state.booking.status],
  (currentExhibitor, cartStatus) => eventId =>
    cartStatus[currentExhibitor?.id]?.[eventId] || null
);
export const BookingSelectorGetOrders = createSelector(
  [ExhSelectorGetCurrent, state => state.booking.order],
  (currentExhibitor, orderList) => {
    const orders = orderList?.[currentExhibitor?.id],
      returnedOrders = {};
    // console.log(cartList);
    for (let i in orders)
      if (
        typeof orders === "object" &&
        orders !== null &&
        orders.hasOwnProperty(i)
      ) {
        returnedOrders[i] = orders[i];
      }
    return returnedOrders;
  }
);

export const BookingSelectorGetOrdersInObj = createSelector(
  [ExhSelectorGetCurrent, state => state.booking?.order],
  (currentExhibitor, orderList) => {
    const orders = orderList?.[currentExhibitor?.id],
      returnedOrders = {};
    for (let i in orders)
      if (
        typeof orders === "object" &&
        orders !== null &&
        orders.hasOwnProperty(i)
      ) {
        returnedOrders[orders[i].cart_id] = orders[i];
      }
    return returnedOrders;
  }
);

export const BookingComputedGetCartTotal = memoize(cart =>
  (cart?.booths || []).reduce((acc, val) => acc + val.snapshot_price, 0)
);

export const BookingSelectorGetEventOfCart = createSelector(
  [state => state.booking.cart, state => state.event],
  (cart, event) => memoize(cartId => event.list[cart[cartId].event_id])
);
