import React from "react";
import styles from "./Terms.module.scss";
const TermsContent = () => {
  return (
    <p className={styles.span}>
Cập nhật lần cuối: 05/10/2023
<br /><br /><h6>TỔNG QUAN</h6>
Cty TNHH Qooee Việt Nam (sau đây gọi là "Đơn vị bán hàng") vận hành trang web này. Qooee Việt Nam cung cấp trang web này, bao gồm tất cả thông tin, công cụ và dịch vụ có sẵn từ trang web này, cho bạn, người dùng, với điều kiện là bạn chấp nhận tất cả các điều khoản, điều kiện, chính sách và thông báo nêu ở đây.
<br />Bất kỳ tính năng hoặc công cụ mới nào được thêm vào trang web hiện tại sẽ phải tuân theo Điều khoản sử dụng. Đơn vị bán hàng có quyền cập nhật, thay đổi hoặc thay thế bất kỳ phần nào trong số này bằng cách đăng các thay đổi lên trang web của mình. Việc bạn tiếp tục sử dụng hoặc truy cập vào trang web sau khi đăng bất kỳ thay đổi nào sẽ cấu thành sự chấp nhận những thay đổi đó.
<br /><br /><h6>CÁC ĐỊNH NGHĨA</h6>
Thuật ngữ 'Người thuê gian hàng' có nghĩa là bất kỳ công ty, đối tác, công ty hoặc cá nhân nào đăng ký Gian hàng trong Sự kiện và hội chợ và sẽ bao gồm tất cả nhân viên, người phục vụ và đại lý của họ.
<br />Thuật ngữ 'Sự kiện' sẽ đề cập đến (các) sự kiện mà Người thuê gian hàng đang đăng ký.
<br />Thuật ngữ 'Đơn vị tổ chức' có nghĩa là: Đơn vị tổ chức ra sự kiện và sở hữu gian hàng được đăng bán trên website.
<br />Thuật ngữ 'Gian hàng' bao gồm gian hàng có khung hoặc không gian thô, được cung cấp bởi Đơn vị tổ chức.
<br />Thuật ngữ 'Cổng thông tin trực tuyến' sẽ đề cập đến trang nơi Người thuê gian hàng có thể đặt chỗ cho Sự kiện và thanh toán trực tuyến cho đơn đăng ký.
<br />Thuật ngữ 'Dữ liệu đăng ký' có nghĩa là tất cả thông tin và dữ liệu mà Người thuê gian hàng cung cấp trong hệ thống đăng ký, bao gồm nhưng không giới hạn ở dữ liệu cá nhân như tên hoặc tên doanh nghiệp và thông tin liên hệ cụ thể là địa chỉ cư trú hoặc doanh nghiệp, địa chỉ email , số điện thoại di động hoặc điện thoại, quốc tịch, giới tính, ngày sinh, tình trạng hôn nhân, ảnh và dữ liệu nghe nhìn khác, thông tin việc làm và dữ liệu tài chính như số thẻ tín dụng, số thẻ ghi nợ hoặc thông tin tài khoản ngân hàng.
<br />Bằng cách đăng nhập vào cổng trực tuyến và đăng ký Gian hàng trong Sự kiện, Người thuê gian hàng đồng ý bị ràng buộc bởi các điều khoản và điều kiện sau.
<br /><br /><h6>ĐƠN ĐĂNG KÝ GIAN HÀNG</h6>
Đơn đăng ký Sự kiện chỉ được thực hiện thông qua Cổng thông tin trực tuyến do trang web quy định, đặc biệt là QOOEE. Việc sử dụng Mẫu đơn đăng ký không còn cần thiết nữa và Đơn vị tổ chức đã ngừng sử dụng nó.
<br />Để đăng ký tham dự Sự kiện thành công, Người thuê gian hàng sẽ được yêu cầu đăng nhập vào Cổng thông tin trực tuyến bằng cách tạo tài khoản và mật khẩu bằng cách hoàn thành biểu mẫu đăng ký trực tuyến của Cổng thông tin trực tuyến, yêu cầu một số thông tin và dữ liệu nhất định ("Dữ liệu đăng ký" ). Bằng cách đăng ký, Người thuê gian hàng đồng ý rằng tất cả thông tin được cung cấp trong Dữ liệu đăng ký là đúng và chính xác.
<br />Hệ thống đặt chỗ qua internet được liên kết trực tiếp với phần mềm đặt chỗ và Đơn vị tổ chức có thể sử dụng dịch vụ email, nhắn tin hoặc trò chuyện để liên lạc với Người thuê gian hàng về trạng thái đặt chỗ và thông báo về mọi sự kiện hoặc thông báo sắp tới.
<br />Việc thuê gian hàng trực tuyến thành công sẽ nhận được xác nhận tự động qua email. Không cần xác nhận thêm.
<br />Cần phải thanh toán đầy đủ các khoản phí để hoàn thành đơn đăng ký.
<br />Đơn vị tổ chức có quyền chấp nhận hoặc từ chối bất kỳ đơn đăng ký nào mà không đưa ra bất kỳ lý do nào.
<br /><br /><h6>CHÍNH SÁCH THANH TOÁN</h6>
Người thuê gian hàng phải tuân thủ nghiêm ngặt các điều khoản thanh toán của Đơn vị tổ chức. Sau khi xác nhận việc đặt chỗ, Người thuê gian hàng phải thanh toán đầy đủ các khoản phí và/hoặc mức giá chưa thanh toán.
<br />Ngoại trừ quyết định duy nhất của Đơn vị tổ chức và Đơn vị bán hàng dựa trên xếp hạng tín dụng của Người thuê gian hàng có thể dễ dàng thu thập được từ hệ thống, Người thuê gian hàng có thể được phép thanh toán trả góp số dư chưa thanh toán theo lịch trình do Đơn vị tổ chức cung cấp.
<br />Việc Người thuê gian hàng không thanh toán và hoặc từ chối thanh toán đúng hạn sẽ dẫn đến việc hủy bỏ việc phân bổ Gian hàng của Người thuê gian hàng theo quyết định của Đơn vị tổ chức và mọi khoản thanh toán được thực hiện, nếu có, sẽ không được hoàn trả.
<br />Bất kỳ khoản thanh toán dư của khoản thanh toán từ một Sự kiện đều không được chấp nhận là khoản thanh toán cho Sự kiện đó.
<br /><br /><h6>PHÂN BỔ, SẮP XẾP GIAN HÀNG</h6>
Người thuê gian hàng có thể thông báo cho Đơn vị tổ chức về lựa chọn Gian hàng ưa thích của họ khi đặt chỗ qua Cổng thông tin trực tuyến. Đây không được coi là quyền được hưởng của người thuê gian hàng trên các Gian hàng.
<br />Đơn vị tổ chức không có nghĩa vụ phân bổ Gian hàng theo các yêu cầu ưu tiên từ Người thuê gian hàng.
<br />Việc phân bổ gian hàng chỉ được xác nhận sau khi có thông báo của Đơn vị tổ chức cho Người thuê gian hàng.
<br />Đơn vị tổ chức có quyền phân bổ lại (các) Gian hàng Sự kiện bất cứ lúc nào theo quyết định riêng của mình nếu được coi là có lợi nhất cho toàn bộ Sự kiện.
<br />Vào bất kỳ thời điểm nào trước khi tổ chức Sự kiện và/hoặc bất kỳ lúc nào trong thời gian Sự kiện, Đơn vị tổ chức có quyền:
<br />thay đổi Gian hàng được phân bổ cho Người thuê gian hàng;
<br />thay đổi kích thước, kích thước và sơ đồ bố trí của Gian hàng; Và
<br />thực hiện bất kỳ thay đổi cấu trúc nào khác mà Đơn vị tổ chức thấy phù hợp.
<br />Người thuê gian hàng sẽ không có yêu cầu bồi thường thiệt hại do những thay đổi này.
<br />Người thuê gian hàng không có quyền chuyển nhượng khi chưa có sự đồng ý của Đơn vị tổ chức.
<br />
<br /><br /><h6>HỦY GIAN HÀNG</h6>
Nếu Người thuê gian hàng có ý định hủy bỏ việc tham gia, Người thuê gian hàng phải thông báo kịp thời cho Đơn vị tổ chức.
<br />Sẽ không hoàn lại tiền khi hủy bỏ.
<br />Đơn vị tổ chức có quyền yêu cầu Người thuê gian hàng bồi thường về mọi tổn thất, thiệt hại và chi phí phát sinh do Người thuê gian hàng hủy bỏ.
<br /><br /><h6>THAY ĐỔI GIAN HÀNG</h6>
Nếu có yêu cầu thay đổi Gian hàng và giá của Gian hàng được yêu cầu cao hơn, Người thuê gian hàng phải chịu chênh lệch giá và thanh toán số tiền còn lại theo thời hạn quy định.
<br />Nếu giá thấp hơn sẽ không được hoàn lại phần chênh lệch giá.
<br /><br /><h6>THAM GIA SỰ KIỆN VÀ SỬ DỤNG GIAN HÀNG</h6>
Người thuê gian hàng đồng ý rằng các vật trưng bày được yêu cầu phải phù hợp với tính chất và mục đích của Sự kiện và quy định của Đơn vị tổ chức.
<br />Người thuê gian hàng đồng ý tuân theo chặt chẽ với các quy định đề ra của Đơn vị tổ chức tại Sự kiện.
<br /><br /><h6>THAY ĐỔI ĐỊA ĐIỂM, NGÀY VÀ THỜI GIAN</h6>
Đơn vị tổ chức có quyền thay đổi địa điểm, ngày và/hoặc thời gian của Sự kiện. Đơn vị tổ chức sẽ thực hiện các biện pháp hợp lý để thông báo cho Người thuê gian hàng về những thay đổi này.
<br />Trong trường hợp có bất kỳ thay đổi nào về địa điểm, ngày và/hoặc thời gian của Sự kiện, thỏa thuận này sẽ vẫn có hiệu lực.
<br />Đơn vị tổ chức cũng có quyền hủy Sự kiện trong những trường hợp bất khả kháng. Trong trường hợp Đơn vị tổ chức hủy bỏ Sự kiện như vậy, Người thuê gian hàng sẽ không có quyền yêu cầu bất kỳ yêu cầu bồi thường nào liên quan đến việc họ đăng ký tham gia.
<br /><br /><h6>KHIẾU NẠI</h6>
Mọi khiếu nại liên quan đến Sự kiện sẽ được Ban tổ chức trực tiếp xử lý. 
<br />Các khiếu nại liên quan đến sử dụng Cổng thông tin sẽ được Qooee tiếp nhận và xử lý.
<br /><br /><h6>LUẬT CHI PHỐI</h6>
Các điều khoản của thỏa thuận này sẽ được điều chỉnh và giải thích theo luật pháp nước CHXHCN Việt Nam. Bất kỳ tranh chấp nào phát sinh từ hoặc liên quan đến vấn đề này sẽ thuộc thẩm quyền xét xử độc quyền của tòa án Singapore mà các bên tham gia thỏa thuận này đệ trình. 
    </p>
  );
}

export default TermsContent;
