export const USER_STATUS = {
  DELETED: -1,
  INACTIVE: 0,
  ACTIVE: 1
};

// TODO: get all event API
export function APIReplParams(path, params = {}) {
  const url = Object.entries(params).reduce((p, [key, value]) => {
    return p.replace(`:${key}`, value);
  }, path);
  if (/:[a-zA-Z]+/.test(url))
    throw new Error(`Lack parameter(s) inside API URL: ${path}`);
  return url;
}
export function APIAuthAPI(user, ApiName, params = undefined) {
  let apiStr = (user === "undefined" ? APIExternal : APIExternal)[ApiName]; //user.obj.role === "USER" 
  // if (typeof apiStr === "undefined") {
  //   throw new Error(`API ${ApiName} doesn't exist!`);
  // }
  if (typeof params === "object") apiStr = APIReplParams(apiStr, params);
  return apiStr;
}
export const APIInternal = {
  GET_ALL_EXHIBITOR: "v1/e/a/get-all",
  GET_ONE_EXHIBITOR: "v1/e/a/get-exh-by-id/:id",
  UPDATE_EXHIBITOR_STATUS: "v1/e/a/status/:id",
  UPDATE_EXHIBITOR_CREDIT: "v1/e/a/credit-rating/:id",
  MODIFY_USER_PROFILE: "v1/a/u/:id/info",
  GET_ALL_EVENT: "v1/evt/a/get-all",
  GET_ONE_EVENT: "v1/evt/a/info/:id",
  UPDATE_EVENT: "v1/evt/a/update-event/:id",
  ADD_EVENT: "v1/evt/a/add-event",
  BOOTH_LAYOUT: "v1/evt/a/booth-layouts/:eventId",
  BOOTH_TYPES_ALL: "v1/b/a/all-booth-types",
  GET_BOOTH_TYPE_BY_SLUG: "v1/b/a/booth-type-by-slug/:slug",
  BOOTH_ADD_TYPE: "v1/b/a/event/:eventId/booth-type",
  BOOTH_EDIT_TYPE: "v1/b/a/event/:eventId/booth-type/:id",
  POST_UPLOAD_BOOTH_SPEC: "/v1/b/a/event/:eventId/booths",
  POST_UPLOAD_BOOTH_PRICING: "/v1/b/a/event/:eventId/pricing",
  GET_ALL_PRICING: "/v1/b/a/event/:eventId/pricing",
  GET_ALL_BOOTHS: "/v1/b/a/event/:eventId/booths",
  DEL_ALL_BOOTHS: "/v1/b/a/event/:eventId/booths",
  DEL_ALL_PRICINGS: "/v1/b/a/event/:eventId/pricing",
  DEL_BOOTH_TYPE: "v1/b/a/booth-type/:id",
  GET_ALL_ORDER: "v1/booking/a/orders"
};
export const APIExternal = {
  GET_ALL_EXHIBITOR: "v1/e/get-all-exhibitors",
  GET_ONE_EXHIBITOR: "v1/e/get-exhibitor-by-id/:id",
  CREATE_EXHIBITOR: "v1/e/create-exhibitor",
  QUERY_USER: "v1/u/info/:email",
  UPDATE_EXHIBITOR: "v1/e/update-exhibitor/:id",
  GET_EXHIBITOR_MEMBERS: "v1/e/exhibitor-member/:id",
  POST_TAG_MEMBER_TO_EXHIBITOR: "v1/e/add-member-to-exhibitor/:id",
  DEL_REMOVE_MEMBER_FROM_EXHIBITOR: "/v1/e/remove-member/:exhibitorId/:userId",
  GET_ALL_EVENT: "v1/evt/get-all",
  GET_ONE_EVENT: "/v1/evt/info/:id/:exhId",
  GET_ALL_BOOTHS: "/v1/b/event/:eventId/exhibitor/:exhId/booths-available",
  GET_ALL_BOOTHS_NO_PRICING: "/v1/b/event/:eventId/booths-available",
  BOOTH_TYPES_ALL: "v1/b/event/:eventId/all-booth-types",
  GET_ALL_CART: "v1/booking/exhibitor/:exhibitorId/basket",
  ADD_TO_CART: "v1/booking/event/:eventId/exhibitor/:exhibitorId/basket",
  ORDER_PLACE_PUT:
    "v1/booking/event/:eventId/exhibitor/:exhibitorId/place-order",
ORDER_CONFIRM_CART:
    "v1/booking/confirm-cart",
ORDER_VALIDATE_CART:
    "v1/booking/validate-cart",
  GET_ALL_ORDER: "v1/booking/exhibitor/:exhibitorId/orders",
  USER_PROFILE: "v1/u/me",
  CHANGE_PASS: "v1/u/change-password",
  UPDATE_FCM_TOKEN: "v1/u/e/fcm-token",
  GET_ALL_NOTIFICATION: "v1/u/e/notifications",
  READ_NOTI: "v1/u/e/notifications/:id",
};
export const APIMisc = {
  POST_UPLOAD: "v1/r/upload",
  POST_UPLOAD_PUBLIC: "v1/r/public-upload",
  GET_ALL_EXHIBITOR_STATUS: "v1/e/all-exhibitor-status"
};


export const optionsExhibitorType = [
  { value: 'retail', label: 'Gian hàng hội chợ - bán lẻ' },
  { value: 'fnb', label: 'Gian hàng ăn uống' },
  { value: 'exhibition', label: 'Gian hàng triển lãm' },
]

export const optionsCategories = [
  {
    type: "Gian hàng ăn uống",
    filter: "fnb",
    options: [
      {
          "label": "Sushi",
          "value": "Sushi"
      },
      {
          "label": "Burgers",
          "value": "Burgers"
      },
      {
          "label": "Đồ nướng",
          "value": "Satay"
      },
      {
          "label": "Mì & Bún",
          "value": "Noodles"
      },
      {
          "label": "Cơm",
          "value": "Rice"
      },
      {
          "label": "Hải sản",
          "value": "Seafood"
      },
      {
          "label": "Kem & Yogurt",
          "value": "Ice-cream & Yogurt"
      },
      {
          "label": "Xúc xích",
          "value": "Hotdogs, Sausages & Sandwiches"
      },
      {
          "label": "Pizza",
          "value": "Pizza"
      },
      {
          "label": "Đồ ăn Âu",
          "value": "Western Food"
      },
      {
          "label": "Đồ ăn Nhật",
          "value": "Japanese Food"
      },
      {
          "label": "Đồ ăn Thái",
          "value": "Thai Food"
      },
      {
        "label": "Đồ ăn Hàn",
          "value": "Korean Food"
      },
      {
          "label": "Đồ ăn Trung",
          "value": "Chinese Food"
      },
      {
        "label": "Khác (Vui lòng liệt kê)",
          "value": "food-others"
      }
  ]
  },
  {
    type: "Gian hàng đồ uống",
    filter: "fnb",
    options: [
      {
          "label": "Cà phê",
          "value": "Coffee"
      },
      {
          "label": "Nước ép",
          "value": "Fruit Juice"
      },
      {
          "label": "Sinh tố",
          "value": "Smoothies"
      },
      {
        "label": "Trà sữa",
        "value": "Milktea"
    },
      {
          "label": "Thức uống nóng",
          "value": "Hot"
      },
      {
          "label": "Thức uống lạnh",
          "value": "Cold"
      },
      {
        "label": "Khác (Vui lòng liệt kê)",
          "value": "drink-others"
      }
  ]
  },
  {
    type: "Gian hàng hội chợ - bán lẻ",
    filter: "retail",
    options: [
      {
          "label": "Đồ trẻ em",
          "value": "Kids"
      },
      {
          "label": "Đồ chơi",
          "value": "Toys"
      },
      {
          "label": "Sách",
          "value": "Books"
      },
      {
          "label": "Trang trí nhà cửa",
          "value": "Home Decor"
      },
      {
          "label": "Văn phòng phẩm",
          "value": "Stationeries"
      },
      {
          "label": "Chăm sóc sức khoẻ",
          "value": "Health & Wellness"
      },
      {
          "label": "Nước hoa / Tinh dầu thơm",
          "value": "Fragrance & Perfume"
      },
      {
          "label": "Làm đẹp",
          "value": "Beauty"
      },
      {
          "label": "Phụ kiện Nam & Nữ",
          "value": "Accessories"
      },
      {
          "label": "Thời trang Nam & Nữ",
          "value": "Fashion"
      },
      {
          "label": "Trang sức",
          "value": "Jewellery"
      },
      {
          "label": "Đồ ăn đóng gói",
          "value": "Pre-packed Food"
      },
      {
          "label": "Thức uống đóng chai",
          "value": "Bottled Drinks"
      },
      {
          "label": "Khác (Vui lòng liệt kê)",
          "value": "retail-others"
      }
  ]
  },
  {
    type: "Gian hàng triển lãm",
    filter: "exhibition",
    options: [
        { "label": "May mặc", "value": "May mặc" },
        { "label": "Điện tử tiêu dùng", "value": "Điện tử tiêu dùng" },
        { "label": "Chăm sóc cá nhân", "value": "Chăm sóc cá nhân" },
        { "label": "Dược phẩm", "value": "Dược phẩm" },
        { "label": "Thực phẩm", "value": "Thực phẩm" },
        { "label": "Công nghệ thông tin", "value": "Công nghệ thông tin" },
        { "label": "Công nghiệp điện tử", "value": "Công nghiệp điện tử" },
        
        {
            "label": "Khác (Vui lòng liệt kê)",
            "value": "exhibition-others"
        }
  ]
  }
]