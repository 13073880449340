import React from "react";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons/faCalendarDay";
import { faReceipt } from "@fortawesome/free-solid-svg-icons/faReceipt";
import { matchPath } from "react-router";
import withRole from "../../HOCs/withRole";
import { branch, compose } from "../../utils/common";

import { ExhSelectorIsUserHasNoExhibitor } from "../../store/exhibitor/selectors";
import { connect } from "react-redux";
import withProps from "../_helpers/withProps";

const withTopBar = Component => {
  return compose(
    withRole,
    branch(
      props => props.role?.isClient,
      connect(state => ({
        isNoExhibitor: ExhSelectorIsUserHasNoExhibitor(state)
      })),
      withProps({ isNoExhibitor: false })
    )
  )(({ loginSuccess, role = null, isNoExhibitor, urlHelper, ...props }) => {
    const isActive = (path, exact = false) => (match, location) => {
        const tmp = matchPath(location.pathname, {
          path: urlHelper(path),
          exact
        });
        return tmp !== null;
      };
      let defaultMenu = [
        // ["/home", isActive("/home"), "Trang chủ"],
        ["/events", isActive("/events"), "Sự kiện"],
        // ["/faq", isActive("/faq"), "FAQ"],
        ["/about-us", isActive("/about-us"), "Về chúng tôi"],
        // ["/terms-of-contract", isActive("/terms-of-contract"), "Điều khoản sử dụng"],
        // ["/privacy-policy", isActive("/privacy-policy"), "Chính sách bảo mật"],
        ["/terms-of-contract", isActive("/terms-of-contract"), "Điều khoản sử dụng"],
        ["/privacy-policy", isActive("/privacy-policy"), "Chính sách bảo mật"],
      ];
    if (loginSuccess === true && role !== null)
    {
      defaultMenu = [
        ...defaultMenu,
      ]
      defaultMenu.unshift(
        ...[
          ["/exhibitors", isActive("/exhibitor"), "Tài khoản người bán hàng", faUsers],
          // role.isAdmin && [
          //   "/exhibitors/status",
          //   isActive("/exhibitor"),
          //   "Exhibitor Status",
          //   null,
          //   { child: 1 }
          // ],
          // ["/event", isActive("/event"), "Events", faCalendarDay],
          [
            "/payment/manage/booking",
            isActive("/payment/manage/booking"),
            "Đặt chỗ của tôi",
            faReceipt,
            { enabled: !isNoExhibitor }
          ],
          [
            "/user/profile",
            isActive("/user/profile"),
            "Hồ sơ cá nhân",
            faReceipt
          ]
        ]
      );
    }

    return (
      <Component
        topBarMenu={defaultMenu.filter(Boolean)}
        isLoginSuccess={loginSuccess}
        {...props}
      />
    );
  });
};

export default withTopBar;
