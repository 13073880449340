import React from "react";
import loadable from "@loadable/component";
import { Redirect, Route } from "react-router";
import { createMemoryHistory } from "history";
import configureStore from "../store/store";
import LoginPageMobile from "./LoginPageMobile/LoginPageMobile";
import ExternalRegisterMobile from "./ExternalRegisterMobile/ExternalRegisterMobile";
import InternalLogin from "./InternalLogin/InternalLogin";
import InternalLoginMobile from "./InternalLoginMobile/InternalLoginMobile";
import { connect, useSelector } from "react-redux";
import ExternalUsersManagement from "./ExternalUsersManagement/ExternalUsersManagement";
import ExternalUsersManagementMobile from "./ExternalUsersManagementMobile/ExternalUsersManagementMobile";
import InternalRegister from "./InternalRegister/InternalRegister";
import ResetPassword from "./ResetPassword/ResetPassword";
import ResetPasswordMobile from "./ResetPasswordMobile/ResetPasswordMobile";
import UserProfile from "./UserProfile/UserProfile";
import Profile from "./Profile/Profile.js";
import { GetUserRoles } from "../store/user/selectors";
import Responsive, { InternalResponsive } from "../components/UI/Responsive/Responsive";
import FHandlerRegistrationDoc from "../pages/FHandlerRegDetail/FHandlerRegistratonDoc";
import ChangePassword from "./ChangePassword/ChangePassword";

const { store } = configureStore();

const LoginPage = loadable(() =>
  import(/* webpackChunkName: 'desktop' */ "./LoginPage/LoginPage")
);
const ExternalRegister = loadable(() =>
  import(
    /* webpackChunkName: 'desktop' */ "./ExternalRegister/ExternalRegister"
  )
);
const Exhibitors = loadable(() =>
  import(
    /* webpackChunkName: "exhibitor-admin" */ "./ExhibitorManagement/ExhibitorManagement"
  )
);
const ExhibitorStatusPage = loadable(() =>
  import(
    /* webpackChunkName: "exhibitor-admin" */ "./ExhibitorStatusPage/ExhibitorStatusPage"
  )
);
const ExhibitorDetail = loadable(() =>
  import(
    /* webpackChunkName: "exhibitor" */ "./ExhibitorDetail/ExhibitorDetail"
  )
);
const ExhibitorDetailMobile = loadable(() =>
  import(
    /* webpackChunkName: 'exhibitor-mobile' */ "./ExhibitorDetailMobile/ExhibitorDetailMobile"
  )
);
const ExhibitorAdd = loadable(() =>
  import(/* webpackChunkName: "exhibitor" */ "./ExhibitorAdd/ExhibitorAdd")
);
const FoodLicences = loadable(() =>
  import(/* webpackChunkName: "exhibitor" */ "./FoodLicences/FoodLicences")
);
const HomePage = loadable(() =>
  import(/* webpackChunkName: "event-page" */ "./HomePage/HomePage")
);
const About = loadable(() =>
  import(/* webpackChunkName: "event-page" */ "./About/About")
);
// const FAQ = loadable(() =>
//   import(/* webpackChunkName: "event-page" */ "./FAQ/FAQ")
// );
const Terms = loadable(() =>
  import(/* webpackChunkName: "event-page" */ "./Terms/Terms")
);
const Privacy = loadable(() =>
  import(/* webpackChunkName: "event-page" */ "./Privacy/Privacy")
);
const EventPage = loadable(() =>
  import(/* webpackChunkName: "event-page" */ "./EventPage/EventPage")
);
const Checkout = loadable(() =>
  import(/* webpackChunkName: "event-page" */ "./Checkout/Checkout")
);
const Confirmation = loadable(() =>
  import(/* webpackChunkName: "event-page" */ "./Confirmation/Confirmation")
);
const Page404 = loadable(() =>
  import(/* webpackChunkName: "event-page" */ "./404Page/Page404")
);
const EventBoothBooking = loadable(() =>
  import(
    /* webpackChunkName: "event-booth-booking" */ "./EventBoothBooking/EventBoothBooking"
  )
);
const EventBoothBookingMobile = loadable(() =>
  import(
    /* webpackChunkName: 'event-booth-booking-mobile' */ "./EventBoothBookingMobile/EventBoothBookingMobile"
  )
);
const EventManagement = loadable(() =>
  import(
    /* webpackChunkName: 'event-management' */ "./EventManagement/EventManagement"
  )
);

const NotificationPage = loadable(() =>
import(
  /* webpackChunkName: 'event-management' */ "./NotificationPage/NotificationPage"
)
);
const EventAdd = loadable(() =>
  import(/* webpackChunkName: 'event-add' */ "./EventAdd/EventAdd")
);

const Nomsterhunt = loadable(() =>
  import(/* webpackChunkName: 'event-add' */ "./Nomsterhunt/Nomsterhunt")
);

const injectPrivilegedModule = (
  key,
  adminModule,
  userModule
) => AsyncComponentImport => props => {
  const [component, setComponent] = React.useState([null, null]),
    userRole = useSelector(GetUserRoles);
  React.useEffect(() => {
    const promiseFn = userModule;
    promiseFn().then(({ default: asyncModule }) => {
      store.injectModule(key, asyncModule);
      AsyncComponentImport().then(({ default: AsyncComponent }) => {
        const { action = null, selector = null } = asyncModule;
        setComponent([
          AsyncComponent,
          {
            userRole,
            asyncAction: action,
            asyncSelector: selector
          }
        ]);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [C, asyncProps] = component;
  return C ? <C {...props} {...asyncProps} /> : null;
};
export const injectBookingModule = injectPrivilegedModule(
  "booking",
  () =>
    import(
      /* webpackChunkName: 'payment-admin' */ "../store/booking-admin/module"
    ),
  () => import(/*webpackChunkName: 'payment-user'*/ "../store/booking/module")
);

const BookingPayment = injectBookingModule(() =>
  import(
    /* webpackChunkName: 'payment-desktop' */ "./BookingPayment/BookingPayment"
  )
);
const BookingPaymentManagement = injectBookingModule(() =>
  import(
    /* webpackChunkName: 'payment-desktop' */ "./BookingPaymentManagement/BookingPaymentManagement"
  )
);
const BookingPaymentMobile = injectBookingModule(() =>
  import(
    /* webpackChunkName: 'payment-mobile' */ "./BookingPaymentMobile/BookingPaymentMobile"
  )
);
const BookingPaymentManagementMobile = injectBookingModule(() =>
  import(
    /* webpackChunkName: 'payment-mobile' */ "./BookingPaymentManagementMobile/BookingPaymentManagementMobile"
  )
);
const BookingDetailAsync = injectBookingModule(() =>
  import(
    /* webpackChunkName: 'payment-desktop' */ "./BookingPaymentManagement/AdminDetail"
  )
);

const InvoiceDetail = injectBookingModule(() =>
  import(
    /* webpackChunkName: 'payment-desktop' */ "./InvoiceDetail/InvoiceDetail"
  )
);

export const PrivateRoute = connect(state => ({ user: state.user }))(
  ({ user, isAdminRoute, children, force = false, ...rest }) => {
    // const user = useSelector(state => state.user);
    return (
      <Route
        {...rest}
        render={props => {
          let condition = typeof user.obj?.auth === "object";
          if (isAdminRoute) condition &= user.obj?.role !== "USER";
          else condition &= user.obj?.role === "USER";
          // console.log(condition, rest.path);
          return condition
            ? children
            : !force && (
                <Redirect
                  to={{
                    pathname: isAdminRoute ? "/internal/login" : "/login",
                    state: { from: props.location }
                  }}
                />
              );
        }}
      />
    );
  }
);

const _refineRoute = (r, index) =>
  React.cloneElement(r, {
    key: `route-${index}`
  });

const universalRoutes = [
  <Route path={"/login"}>
    <Responsive mobile={LoginPageMobile} desktop={LoginPage} />
  </Route>,
  /* EXTERNAL */
  <Route path={"/register"}>
    <Responsive mobile={ExternalRegisterMobile} desktop={ExternalRegister} />
  </Route>,
  <Route path={"/reset-password"}>
    <Responsive mobile={ResetPasswordMobile} desktop={ResetPassword} />
  </Route>,
  <Route path={"/event/:id"}>
    <Responsive desktop={EventBoothBooking} mobile={EventBoothBookingMobile} />
  </Route>,
  // <Route path={"/"}>
  //   <EventPage />
  // </Route>,
  <Route path={"/home"}>
    <EventPage />
  </Route>,
   <Route path={"/event"}>
   <EventPage />
 </Route>,
  <Route path={"/events"}>
    <EventPage />
  </Route>,
  <PrivateRoute path={"/checkout"}>
    <Checkout />
  </PrivateRoute>,
    <PrivateRoute path={"/payment-confirmation"}>
    <Confirmation />
  </PrivateRoute>,
  <Route path={"/terms-of-contract"}>
    <Terms />
  </Route>,
   <Route path={"/privacy-policy"}>
   <Privacy />
 </Route>,
   <Route path={"/404"}>
    <Page404 />
   </Route>,
  <Route path={"/about-us"}>
    <About />
  </Route>,
  <PrivateRoute path={"/exhibitors"}>
    {/* client must redirect into /exhibitor/detail */}
    <Redirect to={"/exhibitor/detail/"} />
  </PrivateRoute>,
  <PrivateRoute path={"/exhibitor/add"}>
    {/* mobile reuse detail page while desktop using separated dialog */}
    <Responsive mobile={ExhibitorDetailMobile} desktop={ExhibitorAdd} />
  </PrivateRoute>,
  <PrivateRoute path={"/exhibitor/detail/:id?"}>
    <Responsive desktop={ExhibitorDetail} mobile={ExhibitorDetailMobile} />
  </PrivateRoute>,
  <PrivateRoute path={"/payment/manage"}>
    <BookingPaymentManagement />
    {/* <Responsive
      desktop={BookingPaymentManagement}
      mobile={BookingPaymentManagementMobile}
    /> */}
  </PrivateRoute>,
  <PrivateRoute path={"/notifications"}>
    <NotificationPage />
 </PrivateRoute>,
  <PrivateRoute path={"/payment/detail/:type/:id"}>
    <Responsive desktop={BookingPayment} mobile={BookingPaymentMobile} />
  </PrivateRoute>,
  <PrivateRoute path={"/booking-detail/:id"}>
    <BookingDetailAsync />
  </PrivateRoute>,
  <PrivateRoute path={"/invoice/:orderId/:invoiceNo"}>
    <InvoiceDetail />
  </PrivateRoute>,
  <PrivateRoute path={"/user/profile"}>
    <Profile />
  </PrivateRoute>,
  <PrivateRoute path={"/user/change-password"}>
    <ChangePassword />
  </PrivateRoute>,
  /* INTERNAL */
  <Route path={"/internal/login"}>
    <Responsive desktop={InternalLogin} mobile={InternalLoginMobile} />
  </Route>,
  <Route path={"/internal/reset-password"}>
    <ResetPassword isAdmin={true} />
  </Route>,
  <Route path={"/internal/register/continue"}>
    <InternalRegister />
  </Route>,
  <PrivateRoute isAdminRoute path={"/internal/register"}>
    <InternalRegister />
  </PrivateRoute>,
  <PrivateRoute isAdminRoute path={"/internal/users"}>
    <InternalResponsive desktop={ExternalUsersManagement} mobile={ExternalUsersManagementMobile} />
  </PrivateRoute>,
  <PrivateRoute isAdminRoute path={"/internal/exhibitors/status/:id?"}>
    <ExhibitorStatusPage />
  </PrivateRoute>,
  <PrivateRoute isAdminRoute path={"/internal/exhibitors"}>
    <Exhibitors />
  </PrivateRoute>,
  <PrivateRoute isAdminRoute path={"/internal/exhibitor/detail/:id?"}>
    <ExhibitorDetail />
  </PrivateRoute>,
  <PrivateRoute isAdminRoute path={"/internal/event/:id"}>
    <EventBoothBooking />
  </PrivateRoute>,
  <PrivateRoute isAdminRoute path={"/internal/event"}>
    <EventManagement />
  </PrivateRoute>,
  <PrivateRoute isAdminRoute path={"/internal/user-info/:email"}>
    <UserProfile />
  </PrivateRoute>,
  <PrivateRoute isAdminRoute path={"/internal/payment/manage"}>
    <BookingPaymentManagement />
  </PrivateRoute>,
  <PrivateRoute isAdminRoute path={"/internal/booking-detail/:id"}>
    <BookingDetailAsync />
  </PrivateRoute>,
  <Route path={"/nomsterhunt"}>
  <Nomsterhunt />
</Route>
];

const routes = [
  ...universalRoutes,
  process.env.NODE_ENV === "development" && (
    <Route path={"/test/registration-form"}>
      <FHandlerRegistrationDoc />
    </Route>
  ),
  /* FINAL MATCH */
  <Route path={"/internal"}>
    <Redirect to={"/internal/login"} />
  </Route>,
  // <Route path={"/"}>
  //   <Redirect to={"/login"} />
  // </Route>
  <Route path={"/"}>
    <Redirect to={"/events"}/>
  </Route>
]
  .filter(Boolean)
  .map(_refineRoute);

// export const history = createBrowserHistory();

export const modal = {
  addEvent: EventAdd
};

export default routes;

export const dialogs = [
  ...universalRoutes,
  <PrivateRoute
    path={"/:type(food-stall|food-handler|registration)/:exhId/:id"}
  >
    <FoodLicences />
  </PrivateRoute>,
  <PrivateRoute
    isAdminRoute
    path={"/internal/:type(food-stall|food-handler|registration)/:exhId/:id"}
  >
    <FoodLicences />
  </PrivateRoute>
].map(_refineRoute);

export const modalHistory = createMemoryHistory();
